import ReactGA from "react-ga4"

const watermarkParams = (watermark) => {
  if (watermark) {
    let params = "";
    const watermarkPosition = watermark.position ?? "bottom-left";
    const isFullImageWatermark = watermark.fullWatermark;
    if (watermark.logoText) {
      params +=
        "&wt=" +
        watermark.logoText +
        "&wtc=" +
        watermark.fontColor +
        "&wts=" +
        watermark.fontSize +
        "&wp=" +
        watermarkPosition +
        "&wo=" +
        +watermark.opacity * 0.1 +
        "&wof=" +
        +watermark.xOffset +
        "," +
        +watermark.xOffset +
        "&wtf=" +
        watermark.font;
    }
    if (watermark.logoUrl) {
      const logo = watermark.logoUrl.replace("watermark/", "");
      params =
        "&wm=" +
        logo +
        "&ws=" +
        watermark.logoScale +
        "&wp=" +
        watermarkPosition +
        "&wof=" +
        +watermark.xOffset +
        "," +
        +watermark.yOffset +
        "&wo=" +
        +watermark.opacity * 0.1 +
        "&wfi=" +
        isFullImageWatermark;
    }
    return params;
  }
  return null;
};

const checkIsDeviceIOS = () =>
  [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform);

const privacyChangeDisabled = () => {
  window.Toast.fire({
    icon: "error",
    title: "You cannot make this image public",
  });
};

const validateLink = (link) => {
  if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
    return link;
  } else {
    return "https://" + link;
  }
};

const printSuccessToast = ()=>{
  window.Toast.fire({
    icon: "Success",
    title: "Photo sent for printing"
  })
}

const fireSingleDownloadGaEvent = (filename="" , userId = 0)=>{
  ReactGA.event({
    category: "Download",
    action: "single_image_download",
    value: userId,
    label: filename
  })
}

export {
  watermarkParams,
  checkIsDeviceIOS,
  privacyChangeDisabled,
  validateLink,
  printSuccessToast,
  fireSingleDownloadGaEvent
};
