import { useRef, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

import photo from "../../../assets/images/photo.png";
import { useEvent } from "../../../Store/event";
import { useUser } from "../../../Store/user";
import { faceSearch } from "../../../apis/facesearch";
import { useFolder } from "../../../Store/folder";
import {
  FACE_SEARCH_NO_RESULT,
  MAX_UPLOAD_IMAGE_SIZE,
  MY_PHOTOS,
} from "../../../constants";

const UploadImage = (props) => {
  const inputRef = useRef();
  const imageRef = useRef();

  const { removeMyPhotosFolder, setMyPhotos } = useFolder();
  const { user, setUser } = useUser();
  const eventDetails = useEvent().response;
  const navigate = useNavigate();
  const { folderName } = useParams();

  const { setLoading, close, setShowPreReg } = props;

  const handleFileUploadChange = async (e) => {
    if (
      e.target.files[0].size &&
      e.target.files[0].size > MAX_UPLOAD_IMAGE_SIZE
    ) {
      window.Toast.fire({
        icon: "error",
        title: "Image size is more than 6 MB",
      });
      return;
    }
    var FR = new FileReader();
    FR.addEventListener("load", async function (e) {
      imageRef.current.src = e.target.result;
      try {
        setLoading(true);
        let result = await faceSearch(
          user.id,
          eventDetails.data.id,
          e.target.result,
          eventDetails.data.isPublished
        );
        if (result.error) {
          removeMyPhotosFolder();
          close();
          Swal.fire(FACE_SEARCH_NO_RESULT).then(function () {
            setUser({ ...user, search_id: null });
            setMyPhotos([]);
          });
        } else if (result.response.status === 1 && result.response.search_id) {
          setUser({ ...user, search_id: result.response.search_id });
          if (folderName !== MY_PHOTOS.slug) {
            navigate(`/view/${eventDetails.data.slug}/my-photos`, {
              replace: true,
            });
          }
          setTimeout(() => {
            close();
            const galleryApp = document.getElementById("photoGalleryContainer");
            galleryApp?.scrollIntoView();
          }, 200);
        } else if (result.response.status === 2) {
          // close();
          // window.Toast.fire({
          //   icon: "success",
          //   title: "Face registration successful",
          // });
          setShowPreReg(true);
          setLoading(false);
        }
      } catch (e) {
        removeMyPhotosFolder();
        close();
        console.log(e);
        Swal.fire(FACE_SEARCH_NO_RESULT).then(function () {
          setUser({ ...user, search_id: null });
          setMyPhotos([]);
        });
      }
    });
    FR.readAsDataURL(e.target.files[0]);
  };

  return (
    <>
      <div className="text-xl md:text-2xl  text-neutral-800 dark:text-slate-50 lg:mt-6 text-center  p-[10px] font-medium  md:w-[31.063rem]">
        We can’t seem to access your camera, try again or upload a picture.
      </div>
      <div className="flex justify-center">
        <div
          className="border-dashed border rounded-[100%] dark:border-slate-600 oval-shape cursor-pointer dark:shadow-dark-input shadow-dark-input-bottom-right md:pt-[105px] md:pb-[105px] md:pl-[42px] md:pr-[41px]"
          onClick={() => {
            inputRef.current.click();
          }}
        >
          <div className="flex justify-center mt-[50%] md:m-0">
            <img
              ref={imageRef}
              src={photo}
              width="60"
              text-align="center"
              height="60"
              alt="Browsre your photo"
            />
          </div>
          <div className="underline text-sm font-semibold text-cyan text-center pt-5">
            Browse
          </div>
        </div>
        <div className="text-sm font-semibold text-neutral-800 dark:text-slate-50 text-center flex justify-center cursor-pointer lg:hidden block">
          <input
            ref={inputRef}
            accept="image/jpeg, image/jpg, image/png"
            type="file"
            className="hidden"
            onChange={handleFileUploadChange}
          />
        </div>
      </div>
    </>
  );
};

export default UploadImage;
