import React,{ useState, useContext, useEffect } from "react";
import BigPlus from "../../../assets/icons/BigPlus";
import FavoritiesDialog from "../../../pages/GalleryView/components/FavoritiesDialog";
import { ThemeContext } from "../../App";
import "./createNewFolder.css";

export const CreateNewFolder = ({ size }) => {
    const [newListPopUp, setNewListPopUp] = useState(false)
    const theme = useContext(ThemeContext);
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 768;
    return (
        <div className='shadow-dark-input-bottom-right dark:shadow-dark-input new-folder flex justify-center text-center text-sm dark:text-light-gray cursor-pointer h-auto' onClick={() => {
            setNewListPopUp(true);
        }}>
            <div className='self-center'>
                <div className='flex justify-center'>
                    <BigPlus width={isMobile ? '24' :'36'} height={isMobile ? '24' :'36'} color={theme === 'dark' ? 'white' : 'black'}></BigPlus>
                </div>
                <div className='text-xs md:text-2xl mt-[10px] font-medium'>New Folder</div>
            </div>
            <FavoritiesDialog open={newListPopUp} close={() => {
            setNewListPopUp(false)
            }}/>
        </div>
    );
};

export default CreateNewFolder;
