/* This example requires Tailwind CSS v2.0+ */
import { useRef, useState } from "react";
import { Input } from "../../../components/common/Input";
import InstagramIcon from "../../../assets/images/Instagram-icon.png";
import FacebookIcon from "../../../assets/images/Facebook-icon.png";
import TwitterIcon from "../../../assets/images/Twitter-icon.png";
import YoutubeIcon from "../../../assets/images/Youtube-icon.png";
import WhatsappIcon from "../../../assets/images/Whatsapp-icon.png";
import LinkedinIcon from "../../../assets/images/Linkedin-icon.png";
import { useEvent } from "../../../Store/event";
import Button from "../Button";
import "./Share.css";

export default function Share({ shareUrl }) {
  let eventDetails = useEvent().response;
  const [show, setShow] = useState(false);

  const socialMediaIcons = [
    {
      name: "Facebook",
      icon: FacebookIcon,
      href: "https://www.facebook.com/sharer/sharer.php?u=" + shareUrl,
    },
    {
      name: "Twitter",
      icon: TwitterIcon,
      href: "https://twitter.com/intent/tweet?url=" + shareUrl,
    },
    {
      name: "WhatsappIcon",
      icon: WhatsappIcon,
      href: "https://wa.me/?text=Check%20Out%20my%20Image " + shareUrl,
    },
    {
      name: "Linkedin",
      icon: LinkedinIcon,
      href:
        "https://www.linkedin.com/feed/?shareActive=true&shareUrl= " + shareUrl,
    },
  ];

  return (
    <div className="inline-block bg-theme-light dark:bg-dark-gray text-dark-gray1 dark:text-light-gray align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:mt-3 sm:align-middle sm:max-w-lg w-full ">
      <div className="sm:flex sm:items-start md:flex md:items-start lg:flex lg:items-start mb-5">
        <div className="mt-3 sm:mt-0 sm:text-left md:text-left lg:text-left w-full">
          <div className="mt-2">
            <div className="shareDiv shadow-light-share dark:shadow-dark-share px-2 py-7 rounded-xl flex">
              <p className="overflow-hidden whitespace-nowrap w-[70%]	sm:w-[80%] text-dark-gray1 text-sm dark:text-light-gray">
                {shareUrl}
              </p>
              <a
                onClick={() => {
                  setShow(true);
                  navigator.clipboard.writeText(shareUrl);
                }}
                className="cursor-pointer text-[#00b7b8] overflow-hidden whitespace-nowrap text-right w-[30%]	sm:w-[20%] text-sm font-semibold"
              >
                Copy Link
              </a>
            </div>
            {show ? (
              <span
                className="px-1"
                style={{ fontSize: 11, fontWeight: "bold", color: "#708757" }}
              >
                Link Copied to Clipboard
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-5 px-1 sm:flex sm:flex-row">
        {socialMediaIcons.map((item, index) => (
          <div className="mr-6" key={index}>
            <a href={item.href} target="_blank">
              <img
                className="shadow-xl rounded-md"
                src={item.icon}
                alt="Share"
                width="30"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
