import React from "react";

function Button(props) {
    const { className = "", text = "", disabled = false, onClick=()=>{}, bg="bg-button" } = props;
    const btnColor = disabled
        ? "bg-light-disabled-button dark:bg-dark-disabled-button dark:text-gray text-black-gray"
        : "dark:text-light-gray text-light-gray";
    return (
        <button
            type="button"
            className={`rounded-lg py-[9px] px-[16px] font-semibold text-base ${btnColor} ${className} ${bg}`}
            disabled={disabled}
            onClick={onClick}
        >
            {text}
        </button>
    );
}

export default Button;
