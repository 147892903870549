import React, {
  useContext,
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import { useSearchParams } from "react-router-dom";
import PinInput from "react-pin-input";

import Text from "../../../components/common/Text";
import Button from "../../../components/common/Button";
import BasicInfo from "../common/BasicInfo";
import { ThemeContext } from "../../../components/App";
import Header from "../../GalleryView/components/Header";
import { useEvent } from "../../../Store/event";
import { useUser } from "../../../Store/user";
import { loginFaceReg, validatePin } from "../../../apis/events";
import Signup from "../Signup";
import { checkAccess } from "../../../apis/facesearch";
import {
  EMAIL_WHERE_DIRECT_FACE_REC,
  EVENT_WHERE_PIN_NOT_REQUIRED,
  EVENT_WITH_DIRECT_FACE_REC,
  EVENT_WITH_SPECIAL_DIGITAL_PURCHASE,
  MAX_FACE_REG_REACHED_POPUP,
  MAX_FACE_SEARCH_LIMIT_REACH_BE_MESSAGE,
  MAX_GUEST_LIMIT_REACH_BE_MESSAGE,
  PHOTOGRAPHER_WITH_DIRECT_FACE_REC,
  PIN_WHERE_PIN_IS_HARD_CODED,
} from "../../../constants";
import Loading from "../../Loading";

function generateRandom6DigitNumber() {
  const randomNumber = Math.floor(Math.random() * 1000000);
  const paddedNumber = randomNumber.toString().padStart(6, "0");
  return paddedNumber;
}

function PinVerify() {
  const theme = useContext(ThemeContext);
  const { user, setUser } = useUser();
  const [error, setError] = useState(false);
  const [searchParams] = useSearchParams();
  const isDarkMode = theme === "dark";
  const eventDetails = useEvent().response;
  const pinInputColor = isDarkMode ? "#eee" : "#222";
  const borderColor = isDarkMode ? "#1a1a1a" : "#f4f6f8";
  const [isDisabled, setDisable] = useState(true);
  const [allowed, setAllowed] = useState(true);
  const [eventRegistrationAccess, setEventRegistrationAccess] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(true);
  let pinRef = useRef();

  const handleLogin = async (
    value,
    doUserSignup = false,
    emailId = "algomage@bharatjodoyatra.app"
  ) => {
    setDisable(false);
    let result = await validatePin(eventDetails.data.id, value);
    if (!result.error) setUser({ ...user, pin: value, isFaceSearch: true });
    else setDisable(true);
    setError(true);
    if (doUserSignup) {
      const userId =
        searchParams.get("userId") || eventDetails.photographer.userId;
      const pin = searchParams.get("pin") || value;
      let newUser = {
        ...user,
        fname: generateRandom6DigitNumber(),
        lname: generateRandom6DigitNumber(),
        email: `${generateRandom6DigitNumber()}_${emailId}`,
        pin: pin,
        isFaceSearch: true,
      };
      let result = await loginFaceReg(eventDetails.data.id, newUser);
      if (result.error === false) {
        let loggedInUserData = {
          ...newUser,
          isValid: true,
          id: result.response.data.id,
          accessToken: result.response.data.accessToken,
        };
        setUser(loggedInUserData);
      } else {
        if (
          [
            MAX_FACE_SEARCH_LIMIT_REACH_BE_MESSAGE,
            MAX_GUEST_LIMIT_REACH_BE_MESSAGE,
          ].includes(result.message)
        ) {
          MAX_FACE_REG_REACHED_POPUP(!!eventDetails.data.isPublished);
        } else {
          setUser({ ...newUser, error: result.message });
        }
      }
      setLoading(false);
    }
    setLoading(false);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(async () => {
    var res = await checkAccess(eventDetails.photographer.id);
    if (!res.error) {
      setAllowed(res.response.faceSearchAccess);
      setEventRegistrationAccess(res.response.eventRegistrationAccess);
      if (
        res.response.faceSearchAccess &&
        (PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
          eventDetails.photographer.id
        ) ||
          EVENT_WITH_DIRECT_FACE_REC.includes(eventDetails.data.id) ||
          EVENT_WHERE_PIN_NOT_REQUIRED.includes(eventDetails.data.id))
      ) {
        let pin = searchParams.get("pin");
        if (
          ["", null, undefined].includes(pin) &&
          EVENT_WITH_DIRECT_FACE_REC.includes(eventDetails.data.id)
        )
          pin = PIN_WHERE_PIN_IS_HARD_CODED[eventDetails.data.id];
        if (EVENT_WHERE_PIN_NOT_REQUIRED.includes(eventDetails.data.id)) {
          if (pin) handleLogin(pin, false);
          else setLoading(false);
        } else
          handleLogin(
            pin,
            true,
            EMAIL_WHERE_DIRECT_FACE_REC[eventDetails.data.id] ?? undefined
          );
      } else setLoading(false);
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useLayoutEffect(() => {
    if (pinRef?.current) pinRef.current.focus();
  }, []);

  const isMobile = width <= 768;
  return loading ? (
    <Loading />
  ) : (
    <div>
      {user.pin ||
      eventDetails.data.isPinDisable ||
      EVENT_WHERE_PIN_NOT_REQUIRED.includes(eventDetails.data.id) ? (
        <Signup />
      ) : (
        <div className="relative">
          <div className="grid lg:grid-cols-coverImg bg-theme-light dark:bg-dark-gray relative">
            <Header isLogin={true} h={true} />
            {allowed ||
            (eventRegistrationAccess && eventDetails.data.isPublished) ? (
              <div className="lg:pl-24 lg:pt-[100px] lg:pr-24 py-[26px] py-4 px-6 md:px-10 relative">
                <BasicInfo />
                <Text
                  as="p"
                  className="text-xs md:text-base font-normal text-dark-gray1 pb-4 dark:text-light-gray"
                >
                  Enter by telling us the secret PIN
                </Text>
                <div className="flex justify-between">
                  <Text
                    as="p"
                    className="mb-2 text-black-gray text-xs md:text-sm sm:pl-4 pl-2"
                  >
                    Enter PIN*
                  </Text>
                  <Text
                    as="p"
                    className="mb-2 text-[#e16a6a] text-xs md:text-sm text-right md:pr-4"
                  >
                    {error && "Invalid PIN"}
                  </Text>
                </div>
                <PinInput
                  ref={(n) => (pinRef = n)}
                  focus
                  autoFocus
                  length={4}
                  initialValue=""
                  onChange={(value, index) => {}}
                  type="numeric"
                  inputMode="number"
                  inputStyle={{
                    borderRadius: "10px",
                    borderColor: error ? "#e16a6a" : borderColor,
                    marginRight: "1.25rem",
                    color: pinInputColor,
                    width: "25%",
                    height: isMobile ? "42px" : "3.75rem",
                    fontSize: isMobile ? "14px" : "1.25rem",
                    fontWeight: "500",
                    lineHeight: isMobile ? "" : "1.75rem",
                  }}
                  onComplete={(value, index) => {
                    handleLogin(value);
                  }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
                <div className="flex justify-center xl:absolute xl:left-2/4 xl:right2/4 xl:-translate-x-2/4  mt-[53px] xl:mb-[4rem] md:mt-[12.875rem]">
                  <Button
                    disabled={isDisabled}
                    className="w-44"
                    onClick={() => {}}
                    text="Next"
                  />
                </div>
              </div>
            ) : (
              <div className="flex lg:pl-24 lg:pt-[100px] lg:pr-16 md:p-8 pt-[26px] pb-[36px] px-6 relative justify-center items-center">
                <h1 className="text-base md:text-3xl text-dark-gray1 pb-4 dark:text-light-gray">
                  This is disabled
                </h1>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PinVerify;
