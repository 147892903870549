import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import React, { useState, useEffect, useMemo } from "react";

import CartIcon from "../../../../../../assets/icons/CartIcon.jsx";
import HeartIcon from "../../../../../../assets/icons/HeartIcon.jsx";
import EyeSlashIcon from "../../../../../../assets/icons/EyeSlashIcon.jsx";
import DownloadIcon from "../../../../../../assets/icons/DownloadIcon.jsx";
import ShareIcon from "../../../../../../assets/icons/ShareIcon.jsx";
import Text from "../../../../../../components/common/Text";
import FavoritiesDialog from "../../../../../../pages/GalleryView/components/FavoritiesDialog";
import { useUser } from "../../../../../../Store/user.js";
import { useEvent } from "../../../../../../Store/event.js";
import { useFavourites } from "../../../../../../Store/favourites.js";
import { useFolder } from "../../../../../../Store/folder.js";
import {
  DOWNLOAD_ORIGINAL_IMAGE,
  EVENT_WHERE_DROPBOX_NEEDED,
  GUEST_UPLOAD_SLUG,
  HIGH_RES_DOMAIN,
  LOWER_RES_DOMAIN,
  MY_PHOTOS,
} from "../../../../../../constants/index.js";
import {
  checkIsDeviceIOS,
  fireSingleDownloadGaEvent,
  privacyChangeDisabled,
} from "../../../../../../helper/index.js";
import PrintIcon from "../../../../../../assets/icons/PrintIcon.jsx";
import HeartOutlineIcon from "../../../../../../assets/icons/HeartOutlinedIcon.jsx";

const Options = () => {
  const [isFavoritiesDialogVisible, showFavoritiesDrawer] = useState(false);
  const openFavNewFolder = (e) => {
    showFavoritiesDrawer(!isFavoritiesDialogVisible);
    e.stopPropagation();
  };

  const handleClose = (e) => {
    showFavoritiesDrawer(false);
    e.stopPropagation();
  };
  return (
    <div className="absolute p-4 bg-theme-light dark:bg-dark-gray dark:shadow-dark-input w-40 left-[150px]">
      <label className="text-gray text-xs">Add to</label>
      <Text
        as="p"
        className="text-sm text-dark-gray1 pb-1 dark:text-light-gray"
      >
        My Favourites
      </Text>
      <div className="flex flex-row justify-between" onClick={openFavNewFolder}>
        <Text as="p" className="text-sm text-dark-gray1 dark:text-light-gray">
          New Folder
        </Text>
        <span className="text-dark-gray1 dark:text-light-gray">{">"}</span>
      </div>
      {isFavoritiesDialogVisible && (
        <FavoritiesDialog
          open={isFavoritiesDialogVisible}
          close={handleClose}
        />
      )}
    </div>
  );
};
const ActionsButton = ({
  photo,
  privateMode,
  isFav,
  openShareBox,
  openCartBox,
  openEmailBox,
  downloadAlertShown = true,
  setDownloadAlertShown = () => {},
  folderId,
}) => {
  const { currentFav } = useFavourites();
  const { folder, setPrivacy } = useFolder() || {};
  const [isOptionVisible, showOptions] = useState(false);
  const { user } = useUser();
  const eventDetails = useEvent().response;
  const [photoPrivacy, setPhotoPrivacy] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const { folderName, listId } = useParams();

  const isIosDevice = useMemo(checkIsDeviceIOS, []);

  useEffect(() => {
    setPhotoPrivacy(photo.isPrivate);
  }, [photo]);

  const favClickHandler = (e) => {
    showOptions(!isOptionVisible);
    e.stopPropagation();
  };
  const handleDownload = () => {
    if (isIosDevice) {
      const downloadButtonClicked = () => {
        let a = document.createElement("a");
        document.body.appendChild(a);
        fireSingleDownloadGaEvent(photo.originalName, user.id);
        a.href =
          eventDetails.data.downloadSingleImageSize === DOWNLOAD_ORIGINAL_IMAGE
            ? photo.downloadSrc.replace(LOWER_RES_DOMAIN, HIGH_RES_DOMAIN)
            : photo.downloadSrc;
        a.target = "_blank";
        setTimeout(() => a.click(), 100);
        document.body.removeChild(a);
      };
      if (!downloadAlertShown) {
        Swal.fire({
          icon: "info",
          title: "",
          text: "A new window with the photo will open. Press and hold the photo for option to save.",
        }).then(function (data) {
          if (data.isConfirmed) {
            downloadButtonClicked();
            setDownloadAlertShown(true);
          }
        });
      } else downloadButtonClicked();
      return;
    }
    const src =
      eventDetails.data.downloadSingleImageSize === DOWNLOAD_ORIGINAL_IMAGE
        ? photo.downloadSrc.replace(LOWER_RES_DOMAIN, HIGH_RES_DOMAIN)
        : photo.downloadSrc;
    fetch(src, {
      method: "GET",
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          fireSingleDownloadGaEvent(photo.originalName, user.id);
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", photo.originalName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setPhotoPrivacy(photo.isPrivate);
  }, [photo]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  return (
    <>
      <button
        type="button"
        className="actions-button sm:h-[48px] pb-[10px] pl-[10px] pr-[10px] sm:pt-[5px] sm:pb-[1.25rem] sm:pl-[5px] sm:pr-[1.25rem]"
      >
        <div className="flex justify-between sm:justify-end items-center">
          {/* {user.isAdmin && <DeleteIcon />} */}
          {folderName !== MY_PHOTOS.slug &&
            !!eventDetails.data.showImageShare && (
              <span
                className="sm:ml-[20px]"
                onClick={openShareBox}
                title="Share photo"
              >
                <ShareIcon
                  height={isMobile ? "20" : "20"}
                  width={isMobile ? "20" : "20"}
                />
              </span>
            )}
          {eventDetails.data.downloadSingleImageSize !== "" &&
            eventDetails.data.downloadSingleImageSize !== null &&
            !!!eventDetails.data.isDownloadChargeable && (
              <span
                className="sm:ml-[20px]"
                onClick={(event) => {
                  handleDownload(event);
                }}
                title="Download photo"
              >
                <DownloadIcon
                  height={isMobile ? "20" : "20"}
                  width={isMobile ? "20" : "20"}
                />
              </span>
            )}

          {!!eventDetails.data.showPrintStore &&
            !!eventDetails.photographer.showPrintStore && (
              //eventDetails.country == "India" &&
              <span
                className="sm:ml-[20px]"
                onClick={openCartBox}
                title={
                  !EVENT_WHERE_DROPBOX_NEEDED.includes(eventDetails.data.id)
                    ? "Add image to cart"
                    : "Print image"
                }
              >
                {!EVENT_WHERE_DROPBOX_NEEDED.includes(eventDetails.data.id) ? (
                  <CartIcon height={"24"} width={"24"} />
                ) : (
                  <PrintIcon />
                )}
              </span>
            )}
          {(user.isAdmin ||
            folderName === GUEST_UPLOAD_SLUG ||
            window.location.pathname.includes("results")) &&
            privateMode &&
            !listId && (
              <span
                className={`sm:ml-[20px] ${
                  (!user.isAdmin ||
                    window.location.pathname.includes("results")) &&
                  !photo.isPrivate
                    ? "hidden"
                    : ""
                }`}
                onClick={() => {
                  user.isAdmin
                    ? setPrivacy(photo.key, folderId, photoPrivacy)
                    : privacyChangeDisabled();
                }}
                title={
                  !photo.isPrivate
                    ? "Mark Photo as Private"
                    : "Mark Photo as Public"
                }
              >
                <EyeSlashIcon
                  height={isMobile ? "20" : "20"}
                  width={isMobile ? "20" : "20"}
                  color={photo.isPrivate ? "#00b7b8" : "white"}
                />
              </span>
            )}

          {isFav ? (
            <span
              className="sm:ml-[20px]"
              onClick={openEmailBox}
              title={`Added to ${currentFav?.name}`}
            >
              <HeartIcon height={"24"} width={"24"} color="white" />
            </span>
          ) : (
            <span
              className="sm:ml-[20px]"
              onClick={openEmailBox}
              title={`Add to ${currentFav?.name}`}
            >
              <HeartOutlineIcon height={"24"} width={"24"} color="white" />
            </span>
          )}
        </div>
      </button>
      {isOptionVisible && <Options />}
    </>
  );
};

export default ActionsButton;
