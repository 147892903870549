import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import {
  addFav,
  getFavImages,
  getFavLists,
  removeFav,
} from "../apis/favourites";
import { useEvent } from "./event";
import { useUser } from "./user";

const Favourites = React.createContext();
Favourites.displayName = "Favourites";

export const useFavourites = () => React.useContext(Favourites);

export const FavouritesProvider = ({ children }) => {
  let { user } = useUser();
  let eventDetails = useEvent().response;
  const [favImages, setFavImages] = useState({});
  const [favList, setFavList] = useState([]);
  const params = useParams();
  const [currentFav, setCurrentFav] = useState({
    name: "",
    id: "",
    count: 0,
  });

  const maxFavPage = useRef(1);

  const changeCount = (count = 1) => {
    setCurrentFav((prev) => ({
      ...prev,
      count: prev.count + count,
    }));

    const currentFavListIndex = favList.findIndex(
      (el) => el.id === currentFav.id
    );
    const favListCopy = [...favList];
    favListCopy[currentFavListIndex].count =
      favListCopy[currentFavListIndex].count + count;
    setFavList(favListCopy);
  };

  useEffect(async () => {
    if (user.accessToken) {
      const corporate =
        eventDetails.data.tags.length > 0
          ? eventDetails.data.tags.some((el) => el.title === "Corporate")
          : false;
      let favList = await getFavLists(
        eventDetails.data.id,
        user.accessToken,
        corporate
      );
      /* Fav list response 
        {
          "id": 162381,
          "name": "My Favorite",
          "isDefault": true,
          "maxFavouritesAllowed": null,
          "count": 2,
          "coverImage": "https://img2-stage.pixnif.com/events/21132/images/folders/103125/images/IMG_4810.JPG"
        }
      */
      if (favList.error === false) {
        /** Set favlist and check for default fav list and add that default fav list */
        setFavList(favList.response.data);
        if (params.listId) {
          setCurrentFav(
            ...favList.response.data.filter((el) => el.id === +params.listId)
          );
        } else {
          const defaultFav = favList.response.data.filter((el) => el.isDefault);
          if (defaultFav.length) setCurrentFav(...defaultFav);
        }
      }
    }
  }, [user.accessToken]);

  const getFavouriteImages = async () => {
    try {
      const data = await getFavImages(
        eventDetails.data.id,
        user.accessToken,
        params.listId
      );
      setFavImages(data.response.data);
      maxFavPage.current = data.response?.meta?.last_page;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user.accessToken && params.listId) getFavouriteImages();
    return () => {
      setFavImages([]);
      maxFavPage.current = 1;
    };
  }, [user.accessToken, params.listId]);

  const addtoFav = async (imageId, isRemoval) => {
    if (!currentFav?.id) {
      window.Toast.fire({
        icon: "error",
        title: "Please select a favorite list",
      });
      return false;
    }
    if (isRemoval) {
      let res = await removeFav(
        currentFav.id,
        imageId,
        user.accessToken,
        eventDetails.data.id
      );
      if (!res.error) {
        window.Toast.fire({
          icon: "success",
          title: "Photo removed from " + currentFav?.name,
        });
        changeCount(-1);
        if (params.listId) {
          const imageIndex = favImages.findIndex((el) => el.id === imageId);
          const favImageCopy = [...favImages];
          favImageCopy.splice(imageIndex, 1);
          setFavImages(favImageCopy);
        }
        return true;
      }
    } else {
      let res = await addFav(
        eventDetails.data.id,
        currentFav.id,
        imageId,
        user.accessToken
      );
      if (!res.error) {
        window.Toast.fire({
          icon: "success",
          title: "Photo added to " + currentFav?.name,
        });

        setCurrentFav((prev) => ({
          ...prev,
          count: prev.count + 1,
        }));

        const currentFavListIndex = favList.findIndex(
          (el) => el.id === currentFav.id
        );
        const favListCopy = [...favList];
        favListCopy[currentFavListIndex].count += 1;
        setFavList(favListCopy);
        return true;
      } else {
        window.Toast.fire({
          icon: "error",
          title: res.message ?? "Internal server error",
        });
      }
    }
  };

  const fancyboxFavClicked = async (imageObj, isRemoval) => {
    if (!currentFav?.id) {
      window.Toast.fire({
        icon: "error",
        title: "Please select a favorite list",
      });
      return false;
    }
    try {
      if (isRemoval) {
        let res = await removeFav(
          currentFav.id,
          imageObj.id,
          user.accessToken,
          eventDetails.data.id
        );
        if (!res.error) {
          window.Toast.fire({
            icon: "success",
            title: "Photo removed from " + currentFav?.name,
          });
          changeCount(-1);
        }
        return { isError: false };
      } else {
        let res = await addFav(
          eventDetails.data.id,
          currentFav.id,
          imageObj.id,
          user.accessToken
        );
        if (!res.error) {
          window.Toast.fire({
            icon: "success",
            title: "Photo added to " + currentFav?.name,
          });
          changeCount(1);
          const favId = res.response.data.favourite.id;
          return { favId };
        } else {
          window.Toast.fire({
            icon: "error",
            title: res.message ?? "Internal server error",
          });
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <Favourites.Provider
      value={{
        favImages,
        setFavImages,
        currentFav,
        setCurrentFav,
        addtoFav,
        fancyboxFavClicked,
        favList,
        setFavList,
        maxFavPage,
      }}
    >
      {children}
    </Favourites.Provider>
  );
};
