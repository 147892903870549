import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DownArrow from "../../../../assets/icons/DownArrow";
import { ThemeContext } from "../../../../components/App";
import { GUEST_UPLOAD_SLUG, MY_PHOTOS } from "../../../../constants";
import { useEvent } from "../../../../Store/event";
import { useFolder } from "../../../../Store/folder";
import { useUploader } from "../../../../Store/upload";
import { useUser } from "../../../../Store/user";
import "./galleryTabs.css";

const useIsOverflow = (ref, isVerticalOverflow) => {
  const [isOverflow, setIsOverflow] = React.useState(undefined);
  const { folders } = useFolder();

  React.useLayoutEffect(() => {
    const { current } = ref;
    const { clientWidth, scrollWidth, clientHeight, scrollHeight } = current;
    const trigger = () => {
      const hasOverflow = isVerticalOverflow
        ? scrollHeight > clientHeight
        : scrollWidth > clientWidth;

      setIsOverflow(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [folders, ref, isVerticalOverflow]);

  return isOverflow;
};

const GalleryTabs = ({ active }) => {
  const eventDetails = useEvent().response;
  const { setActiveFolder, folders } = useFolder();
  const theme = useContext(ThemeContext);
  const ref = useRef(null);
  const [iconActive, setIconActive] = useState(0);
  const isOverflow = useIsOverflow(ref, false);
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(true);
  const params = useParams();
  const { user } = useUser();
  const { guestUploadedImages } = useUploader();

  const scroll = (scrollOffset, ranFromEffect = false) => {
    /**If running from effect make scrollEffect as 0 because when user changes the folder the ounting already happened so scrollLeft would have some value and that needs to be initialized to 0 */
    ref.current.scrollLeft = ranFromEffect
      ? 0
      : Math.ceil(ref.current.scrollLeft);
    /**To get the latest value after computing scrollEffect and scrollOffset */
    let currentScrollLeft = ranFromEffect
      ? 0
      : Math.ceil(ref.current.scrollLeft);
    /** If ran from effect scrollOffset is the computed value so directly change scrollLeft with scrollOffset  */
    if (ranFromEffect) {
      if (scrollOffset > ref.current.clientWidth / 2) {
        ref.current.scrollLeft = scrollOffset;
        currentScrollLeft = scrollOffset;
        setLeft(true);
      } else {
        setLeft(false);
        setRight(true);
      }
    } else {
      ref.current.scrollLeft += scrollOffset;
      currentScrollLeft += scrollOffset;
    }

    if (currentScrollLeft < 10) setLeft(false);
    else setLeft(true);

    if (
      parseInt(currentScrollLeft + ref.current.clientWidth) >=
      ref.current.scrollWidth - 10
    ) {
      setRight(false);
    } else setRight(true);
  };

  const refs = folders.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    let allItemWidth = 0;
    folders.map((folder, index) => {
      if (folder.slug === params.folderName) {
        let scrollPos;
        if (index === 0) scrollPos = 0;
        else if (index === folders.length - 1) {
          scrollPos = ref.current.scrollWidth;
        } else {
          scrollPos = allItemWidth;
        }
        scroll(scrollPos, true);
      }
      if (refs[folder.id].current) {
        allItemWidth +=
          refs[folder.id].current?.getBoundingClientRect().width +
          +window
            .getComputedStyle(refs[folder.id].current)
            .marginRight.split("px")[0];
      }
    });
    return () => (allItemWidth = 0);
  }, [active]);

  useEffect(() => {
    if (ref.current.scrollLeft > 10 && !right) setRight(true);
  }, [folders]);

  return (
    <>
      <div
        className={`flex head-anim justify-start pt-5 pb-10 items-center w-full ease-in duration-300 ${
          theme === "dark" ? "dark-bg" : "light-bg"
        } `}
      >
        {isOverflow && (
          <button
            onClick={() => scroll(-100)}
            disabled={!left}
            className="mr-10 flex"
          >
            <DownArrow
              className={"rotate-90"}
              color={
                theme === "dark"
                  ? left
                    ? "white"
                    : "#555555"
                  : left
                  ? "#1a1a1a"
                  : "#cccccc"
              }
            />
          </button>
        )}
        <ul
          className="flex overflow-scroll scrollbar-hidden text-dark-gray1 dark:text-light-gray h-[45px] items-center"
          ref={ref}
        >
          {React.Children.toArray(
            folders.map((folder) => {
              return (
                <>
                  {(folder.publicVideoCount || user.isAdmin
                    ? folder.imagesCount
                    : folder.publicImagesCount) ||
                  (folder.slug === GUEST_UPLOAD_SLUG &&
                    user.accessToken &&
                    guestUploadedImages.length) ? (
                    <li
                      key={folder.id}
                      ref={refs[folder.id]}
                      className={`mr-[6px] md:mr-10 py-[8px]  pr-[15px] first:pl-0  pl-[15px]   md:py-[10px]  uppercase last:md:mr-0  text-sm md:text-base whitespace-nowrap 
                      ${folder.id === active ? " text-blue font-medium" : ""}`}
                    >
                      <Link
                        onClick={() =>
                          setActiveFolder((prev) => ({
                            ...prev,
                            id: folder.id,
                          }))
                        }
                        key={folder.id}
                        to={`/view/${eventDetails.data.slug}/${folder.slug}`}
                      >
                        {folder.name.replace(/-/g, " ")}
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </>
              );
            })
          )}
        </ul>
        {isOverflow && (
          <button
            onClick={() => scroll(100)}
            disabled={!right}
            className={`ml-10`}
          >
            <DownArrow
              className={"rotate-[-90deg]"}
              color={
                theme === "dark"
                  ? right
                    ? "white"
                    : "#555555"
                  : right
                  ? "#1a1a1a"
                  : "#cccccc"
              }
            />
          </button>
        )}
      </div>
    </>
  );
};

export default GalleryTabs;
