import React, { useContext } from 'react';
import { ThemeContext } from "../../components/App";
import StaticLogo from '../../components/common/StaticLogo';
import LeftArrow from '../../assets/icons/LeftArrow';
import Text from '../../components/common/Text';
import ColouredBulletIcon from '../../assets/icons/ColouredBulletIcon';
import OrderSummery from '../../components/common/OrderSummery';
import { Input, InputText } from '../../components/common/Input';
import Button from '../../components/common/Button';

function PaymentCreditCard() {
    const theme = useContext(ThemeContext);
    const iconsColor = theme === "dark" ? "white" : "#222";
    const dark = "#222222";
    return (
        <div className='dark:text-white'>
            <StaticLogo />
            <div className='flex space-x-5 items-center my-10 px-5'>
                <LeftArrow className="rotate-90" color={iconsColor} />
                <Text className="font-extrabold text-xl">Checkout</Text>
            </div>

            <div className='flex justify-between'>

                <div style={{ width: "70%" }}>


                    {/* ---------------- Address ----------------*/}
                    <div className='px-5'>
                        <div className='flex space-x-5 items-start'>
                            <ColouredBulletIcon color="#222222" className="mt-2" />
                            <div className='w-full pr-14'>
                                <Text className="font-semibold text-lg">Address</Text>
                                <hr className='w-full text-black-gray my-4' />
                                Contact Information
                                <div className='mt-6 w-72'>
                                    <div className='flex items-center'>
                                        <input id="radio-1" type="radio" className='w-4 h-4 accent-cyan-600' />
                                        <label for="radio-1" class='ml-3 text-sm'>Viabhav Sharma</label>
                                        <div class='ml-6 text-sm font-semibold underline text-cyan cursor-pointer'>Change</div>
                                    </div>
                                    <div className='text-sm ml-7 mt-4'>Flat 12, floor 6, 123 society name, Mumbai - 123456, Maharashtra Phone number 1234567890</div>
                                </div>

                            </div>
                        </div>
                    </div>


                    {/* ---------------- Payment ----------------*/}
                    <div className='mt-20 px-5 mb-10'>
                        <div className='flex space-x-5 items-start'>
                            <ColouredBulletIcon color="#22b7b9" className="mt-2" />
                            <div className='w-full pr-14'>
                                <Text className="font-semibold text-lg">Payment</Text>
                                <hr className='w-full text-black-gray my-4' />
                                <div className='flex items-center'>
                                    <input id="radio-1" type="radio" className='w-4 h-4 accent-cyan-600' checked={true} />
                                    <label for="radio-1" class='ml-3 text-sm'>Add debit or credit card</label>
                                </div>
                                <div className='mt-5 space-y-10'>
                                    <div className='flex space-x-16'>
                                        <InputText label="Card number" className='w-[350px]' />
                                        <InputText label="Name on card" className='w-[350px]' />
                                    </div>
                                    <div className='flex space-x-10 items-center'>
                                        <div>
                                            <Text className="mb-1 ml-1 text-sm dark:text-gray">Expiry date*</Text>
                                            <div className='flex space-x-3'>
                                                <InputText islabel={false} placeholder="MM" className="w-11 " textcenter={true} />
                                                <InputText islabel={false} placeholder="YYYY" className="w-16" textcenter={true} />
                                            </div>
                                        </div>
                                        <div>
                                            <InputText label="CVV" className="w-28" />
                                        </div>
                                    </div>
                                </div>

                                <Button className="px-12 py-1 my-10 text-base cursor-pointer dark:text-stone-400 text-stone-600" disabled={true} text="Pay" />

                                <div className='flex items-center mt-6'>
                                    <input id="radio-1" type="radio" checked={false} className='w-4 h-4 accent-cyan-600' />
                                    <label for="radio-1" class='ml-3 text-sm'>UPI</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div style={{ width: "30%" }}>
                    <OrderSummery />
                </div>
            </div>
        </div>
    )
}

export default PaymentCreditCard;