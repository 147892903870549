import React, { useContext } from 'react';
import { ThemeContext } from "../../components/App";
import StaticLogo from '../../components/common/StaticLogo';
import LeftArrow from '../../assets/icons/LeftArrow';
import Text from '../../components/common/Text';
import ColouredBulletIcon from '../../assets/icons/ColouredBulletIcon';
import OrderSummery from '../../components/common/OrderSummery';
import { Input, InputText } from '../../components/common/Input';
import Button from '../../components/common/Button';

function SelectAddress() {
    const theme = useContext(ThemeContext);
    const iconsColor = theme === "dark" ? "white" : "#222";
    const dark = "#222222";
    return (
        <div className='dark:text-white'>
            <StaticLogo />
            <div className='flex space-x-5 items-center my-10 px-5'>
                <LeftArrow className="rotate-90" color={iconsColor} />
                <Text className="font-extrabold text-xl">Checkout</Text>
            </div>

            <div className='flex justify-between'>

                <div style={{ width: "70%" }}>


                    {/* ---------------- Address ----------------*/}
                    <div className='px-5'>
                        <div className='flex space-x-5 items-start'>
                            <ColouredBulletIcon color="#22b7b9" className="mt-2" />
                            <div className='w-full pr-14'>
                                <Text className="font-semibold text-lg">Address</Text>
                                <hr className='w-full text-black-gray my-4' />
                                Contact Information
                                <div className='mt-6 w-72'>
                                    <div className='flex items-center'>
                                        <input id="radio-1" type="radio" className='w-4 h-4 accent-cyan-600' checked={true} />
                                        <label for="radio-1" class='ml-3 text-sm'>Viabhav Sharma</label>
                                        <div class='ml-6 text-sm font-semibold underline text-cyan cursor-pointer'>Edit</div>
                                    </div>
                                    <div className='text-sm ml-7 mt-4'>Flat 12, floor 6, 123 society name, Mumbai - 123456, Maharashtra Phone number 1234567890</div>
                                </div>
                                <div class='mt-7 text-sm font-semibold underline text-cyan cursor-pointer'>+ Add new address</div>
                                <Button className="mt-5" text="Select Address" />
                            </div>
                        </div>
                    </div>


                    {/* ---------------- Payment ----------------*/}
                    <div className='mt-20 px-5'>
                        <div className='flex space-x-5 items-start'>
                            <ColouredBulletIcon color="#222222" className="mt-2" />
                            <div className='w-full pr-14'>
                                <Text className="font-semibold text-lg">Payment</Text>
                                <hr className='w-full text-black-gray my-4' />
                            </div>
                        </div>
                    </div>

                </div>

                <div style={{ width: "30%" }}>
                    <OrderSummery />
                </div>
            </div>
        </div>
    )
}

export default SelectAddress;