import React, { useState, useContext, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import "./logo.css";
import Algomage from "../../../assets/images/logo-algo.png";
import AlgomageLight from "../../../assets/images/group-625.svg";
import { ThemeContext } from "../../App";
import { useEvent } from "../../../Store/event";
import { ReactComponent as HamburgerIcon } from "../../../assets/svgs/Hamburger.svg";
import useWindowSize from "../../../hooks/useWindowHook";
import CloseIcon from "../../../assets/icons/CloseIcon";
import InstagramIcon from "../../../assets/images/Instagram-icon.png";
import FacebookIcon from "../../../assets/images/Facebook-icon.png";
import TwitterIcon from "../../../assets/images/Twitter-icon.png";
import YoutubeIcon from "../../../assets/images/Youtube-icon.png";
import LinkedInIcon from "../../../assets/images/Linkedin-icon.png";
import VimeoIcon from "../../../assets/images/Vimeo-icon.png";
import WebIcon from "../../../assets/images/web_icon.png";
import { validateLink } from "../../../helper";
import { useUser } from "../../../Store/user";
import {
  BMS_PHOTOGRAPHER_ID,
  EVENT_WHERE_MAIL_NOT_NEEDED,
} from "../../../constants";

const Logo = ({ staticLogo, logoStyle, cartDownArrow }) => {
  const socialMediaIcons = [
    {
      name: "instagram",
      icon: InstagramIcon,
    },
    {
      name: "facebook",
      icon: FacebookIcon,
    },
    {
      name: "vimeo",
      icon: VimeoIcon,
    },
    {
      name: "youtube",
      icon: YoutubeIcon,
    },
    {
      name: "linkedin",
      icon: LinkedInIcon,
    },
    {
      name: "twitter",
      icon: TwitterIcon,
    },
  ];
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setSocialInfo(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const theme = useContext(ThemeContext);
  const eventDetails = useEvent().response;
  const iconsColor = theme === "dark" ? "#eee" : "#1a1a1a";

  const { isMobile, windowSize } = useWindowSize();
  const { user } = useUser();

  const { showEmail, showPhone, showSocialLinks, showWebsite } =
    eventDetails.photographer;

  const showDropdown = showEmail || showPhone || showSocialLinks || showWebsite;

  BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) &&
    socialMediaIcons.unshift({
      name: "website",
      icon: WebIcon,
    });

  const [showSocialInfo, setSocialInfo] = useState(false);
  const [privacyPopUp, setPrivacyPopUp] = useState(false);
  const [termsPopUp, setTermsPopUp] = useState(false);
  const clickHandler = () => {
    if (!isMobile) setSocialInfo(!showSocialInfo);
  };
  let clsName = `w-[calc(100vw-48px)] md:w-full md:cursor-pointer flex relative gap-4 md:gap-8  md:justify-start items-center z-1 `;
  if (!staticLogo) {
    clsName = clsName + "top-8  z-10";
  } else {
    clsName = clsName + " pics__header-logo-static z-10";
  }

  if (logoStyle) {
    clsName = clsName + ` ${logoStyle}`;
  }

  const removeScroll = () => {
    if (isMobile)
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
  };

  const addScroll = () => {
    if (isMobile) document.getElementsByTagName("html")[0].style.overflow = "";
  };

  useEffect(() => {
    if (showSocialInfo) {
      removeScroll();
    } else if (!showSocialInfo) addScroll();
  }, [showSocialInfo]);

  return (
    <div
      ref={wrapperRef}
      className="w-full text-dark-gray dark:text-light-gray"
    >
      <div className={clsName} onClick={clickHandler}>
        {eventDetails.photographer.brandingAllowed && !!showDropdown && (
          <button
            onClick={() => setSocialInfo(!showSocialInfo)}
            className="cursor-pointer z-20"
          >
            {showSocialInfo ? (
              <CloseIcon width="24" height="24" fill={iconsColor} />
            ) : (
              <HamburgerIcon />
            )}
          </button>
        )}
        {eventDetails.photographer.brandingAllowed &&
        (eventDetails.photographer.logo ||
          eventDetails.photographer.brandName) ? (
          eventDetails.photographer.logo ? (
            <img
              className={`${eventDetails.photographer.id === 231?'pics__header-logo-img-ethereal':'pics__header-logo-img'} ${
                isMobile ? "flex-1 text-center" : ""
              } `}
              width={eventDetails.photographer.id === 231?180:100}
              src={eventDetails.photographer.logo}
              alt="logo"
            />
          ) : (
            <h3
              className={`leading-[1] text-2xl  ${
                showSocialInfo
                  ? "text-dark-gray1 dark:text-light-gray"
                  : "text-light-gray"
              } 
               mr-[8px] md:mr-4  ${isMobile ? "flex-1 text-center" : ""} `}
            >
              {eventDetails.photographer.brandName}
            </h3>
          )
        ) : (
          <img
            className={`mr-[30px]  ${
              showSocialInfo && isMobile ? "invisible" : ""
            }`}
            width={150}
            src={theme === "dark" ? Algomage : AlgomageLight}
            alt="logo"
          />
        )}
      </div>
      {showSocialInfo ? (
        isMobile ? (
          <div
            style={{
              zIndex: 3,
              height:
                windowSize.height -
                (user.isAdmin ? (window.innerWidth >= 400 ? 88 : 112) : 0),
            }}
            className={`absolute  flex flex-col items-center ${BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) ? "justify-start" : "justify-between"} w-screen  bg-brand-white dark:bg-secondary-800 top-0 left-0`}
          >
            <div className="flex justify-center mt-24 items-center flex-col text-dark-gray1 dark:text-light-gray">
              {eventDetails.photographer.website && !!showWebsite && !BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) ? (
                <a
                  href={validateLink(eventDetails.photographer.website)}
                  target={"_blank"}
                  rel="no-ref"
                  className="cursor-pointer mb-[30px] text-2xl"
                >
                  Work
                </a>
              ) : (
                <></>
              )}
              {eventDetails.photographer.phone && !!showPhone ? (
                <a
                  href={"tel:" + eventDetails.photographer.phone}
                  target={"_blank"}
                  rel="no-ref"
                  className="mb-[30px] text-2xl"
                >
                  Call
                </a>
              ) : (
                <></>
              )}
              {eventDetails.photographer.email &&
              !!showEmail &&
              !EVENT_WHERE_MAIL_NOT_NEEDED.includes(eventDetails.data.id) ? (
                <a
                  href={"mailto:" + eventDetails.photographer.email}
                  target={"_blank"}
                  rel="no-ref"
                  className="text-2xl cursor-pointer"
                >
                  Email
                </a>
              ) : (
                <></>
              )}
            </div>
            <div
              id="icons"
              className="flex mb-12 mx-auto items-center flex-col gap-2 justify-center"
            >
              {BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) ? (
              <div className="text-dark-gray1 dark:text-light-gray">Stay updated on all things BookMyShow at:</div>
            ) : (
              <></>
            )}
              {eventDetails.photographer.showSocialLinks &&
              eventDetails.data.showSocialLinks ? (
                <ul className="flex gap-5 justify-center mb-3">
                  {socialMediaIcons.map((item, index) =>
                    eventDetails.photographer[item.name] !== null ? (
                      <li key={index}>
                        <a
                          href={
                            eventDetails.photographer[item.name].indexOf(
                              "https://"
                            ) === 0
                              ? eventDetails.photographer[item.name]
                              : "https://" +
                                eventDetails.photographer[item.name]
                          }
                          target={"_blank"}
                          rel="no-ref"
                        >
                          <img
                            className={`rounded-md h-[33px] max-w-fit`}
                            src={item.icon}
                            alt="side cover"
                          />
                        </a>
                      </li>
                    ) : (
                      <></>
                    )
                  )}
                </ul>
              ) : (
                <></>
              )}
              <Link
                target="_blank"
                to="/privacy-policy"
                className="flex underline text-xs pt-3  font-semibold  text-blue cursor-pointer"
              >
                {BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id)
                  ? "Terms & Conditions"
                  : "Privacy Policy"}
              </Link>
            </div>
          </div>
        ) : (
          <div
            style={{
              zIndex: 3,
              height: windowSize.height + (user.isAdmin ? 64 : 0),
            }}
            className={`absolute  flex flex-col items-center min-w-[400px]  w-full   bg-theme-light dark:bg-secondary-800 top-0 left-0`}
          >
            <div
              className={`flex justify-center mt-28 pl-10 w-full flex-col ${
                (showWebsite || showDropdown || showEmail) &&
                !BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id)
                  ? "pb-44 border-b dark:border-b-brand-white border-b-secondary-800"
                  : ""
              } text-dark-gray1 dark:text-light-gray`}
            >
              {eventDetails.photographer.website &&
              !!showWebsite &&
              !BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) ? (
                <a
                  href={validateLink(eventDetails.photographer.website)}
                  target={"_blank"}
                  rel="no-ref"
                  className="cursor-pointer mb-[30px] text-2xl"
                >
                  Work
                </a>
              ) : (
                <></>
              )}
              {eventDetails.photographer.phone && !!showPhone ? (
                <a
                  href={"tel:" + eventDetails.photographer.phone}
                  target={"_blank"}
                  rel="no-ref"
                  className="mb-[30px] text-2xl"
                >
                  Call
                </a>
              ) : (
                <></>
              )}
              {eventDetails.photographer.email &&
              !!showEmail &&
              !EVENT_WHERE_MAIL_NOT_NEEDED.includes(eventDetails.data.id) ? (
                <a
                  href={"mailto:" + eventDetails.photographer.email}
                  target={"_blank"}
                  rel="no-ref"
                  className="text-2xl cursor-pointer"
                >
                  Email
                </a>
              ) : (
                <></>
              )}
            </div>
            <div
              id="icons"
              className={`flex  mb-12 ${BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) ? "mt-6 px-8" : "mt-12 px-10"} mx-auto items-center flex-col gap-2 justify-center`}
            >
              {BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) ? (
              <div className="text-dark-gray1 dark:text-light-gray whitespace-nowrap mb-2">Stay updated on all things BookMyShow at:</div>
            ) : (
              <></>
            )}
              {eventDetails.photographer.showSocialLinks &&
              eventDetails.data.showSocialLinks ? (
                <ul className="flex gap-5 justify-center mb-3">
                  {socialMediaIcons.map((item, index) =>
                    eventDetails.photographer[item.name] !== null ? (
                      <li key={index}>
                        <a
                          href={
                            eventDetails.photographer[item.name].indexOf(
                              "https://"
                            ) === 0
                              ? eventDetails.photographer[item.name]
                              : "https://" +
                                eventDetails.photographer[item.name]
                          }
                          target={"_blank"}
                          rel="no-ref"
                        >
                          <img
                            className={`rounded-md h-[33px] max-w-fit`}
                            src={item.icon}
                            alt="side cover"
                          />
                        </a>
                      </li>
                    ) : (
                      <></>
                    )
                  )}
                </ul>
              ) : (
                <></>
              )}
              <Link
                target="_blank"
                to="/privacy-policy"
                className="flex underline text-xs pt-3  font-semibold  text-blue cursor-pointer"
              >
                {BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id)
                  ? "Terms & Conditions"
                  : "Privacy Policy"}
              </Link>
            </div>
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default Logo;
