import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Navbar from "./components/Navbar";
import Header from "./components/Header";
import GalleryWrapper from "./components/GalleryWrapper";
import { DrawerPage } from "../../components/common/BottomDrawer";
import FavoriteFolderDrawer from "./components/FavoriteFolderDrawer";
import FolderOptionsDrawer from "./components/FolderOptionsDrawer";
import { useEvent } from "../../Store/event";
import { useUser } from "../../Store/user";
import { FavouritesProvider } from "../../Store/favourites";
import { FolderProvider } from "../../Store/folder";
import { VIEW_SEARCH_ID } from "../../constants";

const GalleryView = ({
  foldersDrawerVisible = false,
  favourtieImagesVisible = false,
}) => {
  const eventDetails = useEvent().response;
  const { user, setUser } = useUser();
  const [searchParam] = useSearchParams();
  const [isFavDrawerVisible, showFavoritiesDrawer] = useState(false);
  const [isFoldersDrawerVisible, showFoldersDrawer] =
    useState(foldersDrawerVisible);
  const [isFavourtieImagesVisible, showFavourtieImages] = useState(
    favourtieImagesVisible
  );
  const [isFolderOptionsDrawerVisible, showFolderOptionsDrawer] =
    useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [faceRecPopUp, setFaceRecPopUp] = useState(false);
  const [uploadFromCoverImage, setUploadFromCoverImage] = useState(false);

  useEffect(() => {
    if (searchParam.get(VIEW_SEARCH_ID)) {
      setUser((prev) => ({
        ...prev,
        search_id: searchParam.get(VIEW_SEARCH_ID),
      }));
    }
  }, []);

  const onWindowResize = () => {
    if (window.innerWidth < 768 && !isMobile) setIsMobile(true);
    else if (window.innerWidth > 768 && isMobile) setIsMobile(false);
  };

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => window.addEventListener("resize", onWindowResize);
  }, []);

  const foldersClickHandler = (e) => {
    !isFoldersDrawerVisible ? (
      showFoldersDrawer(!isFoldersDrawerVisible)
    ) : (
      <></>
    );
    e.stopPropagation();
  };

  const favClickHandler = (e) => {
    showFavoritiesDrawer(!isFavDrawerVisible);
    e.stopPropagation();
  };

  const folderOptionsClickHandler = (e) => {
    showFolderOptionsDrawer(!isFolderOptionsDrawerVisible);
    e.stopPropagation();
  };

  const changeGallery = (e) => {
    showFoldersDrawer(e);
    showFavourtieImages(e);
  };

  const onFavoriteFolderClickHandler = (e) => {
    showFoldersDrawer(!isFoldersDrawerVisible);
    showFavourtieImages(!isFavourtieImagesVisible);
    e.stopPropagation();
  };

  useEffect(() => {
    showFoldersDrawer(foldersDrawerVisible);
  }, [foldersDrawerVisible]);

  useEffect(() => {
    showFavourtieImages(favourtieImagesVisible);
  }, [favourtieImagesVisible]);

  const hasAccess = eventDetails.data.requiresEmail
    ? user.accessToken || user.jwt
    : eventDetails.data.requiresPassword
    ? user.isValid
    : eventDetails.data.isPublished
    ? true
    : user.accessToken || user.jwt;

  // if (
  //   (eventDetails.data.requiresEmail || eventDetails.data.requiresPassword) &&
  //   !user.isValid
  // ) {
  //   return (
  //     <Navigate
  //       to={`/clientlogin/${params.eventName}/${
  //         params.folderName
  //           ? params.folderName
  //           : eventDetails.data.folders[0].slug.toLowerCase()
  //       }`}
  //     />
  //   );
  // } else {
  return (
    <FolderProvider>
      <FavouritesProvider>
        <div className="dark:bg-dark-gray text-dark-gray1">
          {user.isAdmin && <Navbar />}
          <Header
            h
            showAuthButtons={
              !(isFoldersDrawerVisible || favourtieImagesVisible)
            }
            faceRecPopUp={faceRecPopUp}
            setFaceRecPopUp={setFaceRecPopUp}
            uploadFromCoverImage={uploadFromCoverImage}
            setUploadFromCoverImage={setUploadFromCoverImage}
          ></Header>
          {hasAccess && !isFoldersDrawerVisible && (
            <GalleryWrapper
              onFavClickHandler={favClickHandler}
              onFolderClickHandler={foldersClickHandler}
              onFolderOptionsClickHandler={folderOptionsClickHandler}
              changeGallery={changeGallery}
              showFavouriteImages={isFavourtieImagesVisible}
              isFoldersDrawerVisible={isFoldersDrawerVisible}
              scanPopupOpen={faceRecPopUp}
              uploadFromCoverImage={uploadFromCoverImage}
            ></GalleryWrapper>
          )}
          {/* {isFavDrawerVisible && (
                            <DrawerPage
                                component={<FavoritesDrawer />}
                                isOpen={isFavDrawerVisible}
                                setIsOpen={favClickHandler}
                            />
                        )} */}
          {isFoldersDrawerVisible && (
            <FavoriteFolderDrawer
              onFolderClickHandler={foldersClickHandler}
              changeGallery={changeGallery}
              onFavoriteFolderClickHandler={onFavoriteFolderClickHandler}
              isFoldersDrawerVisible={isFoldersDrawerVisible}
            />
            // <DrawerPage
            //     component={<FavoriteFolderDrawer />}
            //     isOpen={isFoldersDrawerVisible}
            //     setIsOpen={foldersClickHandler}
            // />
          )}
          {isFolderOptionsDrawerVisible && (
            <DrawerPage
              component={<FolderOptionsDrawer />}
              isOpen={isFolderOptionsDrawerVisible}
              setIsOpen={folderOptionsClickHandler}
            />
          )}
        </div>
      </FavouritesProvider>
    </FolderProvider>
  );
  // }
};

export default GalleryView;
