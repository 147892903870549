import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import BackButton from "../../components/common/Backbutton";
import StaticLogo from "../../components/common/StaticLogo";
import { useEvent } from "../../Store/event";
import { useUser } from "../../Store/user";
import {
  ENVIRONMENT,
  EVENT_WITH_SPECIAL_DIGITAL_PURCHASE,
  FRAME_DIGITAL,
  HIGH_RES_DOMAIN,
  LOWER_RES_DOMAIN,
  STAGING_ENVIRONMENT_KEY,
} from "../../constants";
import DownloadIcon from "../../assets/icons/DownloadIcon";
import { checkIsDeviceIOS, fireSingleDownloadGaEvent } from "../../helper";
import { getOrderDetails } from "../../apis/order";
import { getDownlaodFolder } from "../../apis/download";
import { postBandwidth } from "../../apis/bandwidth";
import Loading from "../Loading";

function CompleteShopping() {
  const { user } = useUser();
  const { state } = useLocation();
  const eventDetails = useEvent().response;
  const navigate = useNavigate();
  const { orderId, downloadId } = useParams();

  const [showLoader, setShowLoader] = useState(true);
  const [downloadAlertShown, setDownloadAlertShown] = useState(false);
  const [images, setImages] = useState([]);
  const [downloadLink, setDownloadLink] = useState([]);

  const isIosDevice = useMemo(checkIsDeviceIOS, []);

  const handleDownload = (url, name) => {
    if (isIosDevice) {
      const downloadButtonClicked = () => {
        let a = document.createElement("a");
        document.body.appendChild(a);
        fireSingleDownloadGaEvent(name, user.id);
        a.href = url.replace(LOWER_RES_DOMAIN, HIGH_RES_DOMAIN);

        a.target = "_blank";
        setTimeout(() => a.click(), 100);
        document.body.removeChild(a);
      };
      if (!downloadAlertShown) {
        Swal.fire({
          icon: "info",
          title: "",
          text: "A new window with the photo will open. Press and hold the photo for option to save.",
        }).then(function (data) {
          if (data.isConfirmed) {
            downloadButtonClicked();
            setDownloadAlertShown(true);
          }
        });
      } else downloadButtonClicked();
      return;
    }
    const src = url.replace(LOWER_RES_DOMAIN, HIGH_RES_DOMAIN);

    fetch(src, {
      method: "GET",
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          fireSingleDownloadGaEvent(name, user.id);
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", src.split("/").reverse()[0]); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchDetails = async () => {
    try {
      setShowLoader(true);
      const payloadData = {
        orderId,
        eventId: eventDetails.data.id,
        accessToken: user.accessToken,
      };
      const { data } = await getOrderDetails(payloadData);
      const filteredImages = data.orderDetails.filter(
        (el) => el.frame_type === FRAME_DIGITAL
      );
      setImages(filteredImages);
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const downloadingZip = async (link) => {
    const postData = {
      eventId: eventDetails.data.id,
      downloadRequestId: downloadId,
      guestId: user.id,
      url: link,
    };
    // await postBandwidth(postData);
  };

  useEffect(() => {
    if (!user.email) {
      if (
        eventDetails.photographer.domain &&
        user.accessLevel &&
        ENVIRONMENT !== STAGING_ENVIRONMENT_KEY
      ) {
        window.location.assign(
          `//${eventDetails.photographer.domain}/${
            user.accessLevel ?? "facerec"
          }/${eventDetails.data.slug}`
        );
      } else {
        navigate(`/view/${eventDetails.data.slug}`);
      }
    }
  }, []);

  useEffect(() => {
    if (orderId) {
      fetchDetails();
    } else setShowLoader(false);
  }, []);

  useEffect(() => {
    let intitialRequest = 0;
    if (!downloadLink.length && downloadId) {
      const intervalId = setInterval(async () => {
        intitialRequest = intitialRequest + 1;
        let res = await getDownlaodFolder(downloadId, intitialRequest);
        if (!res.error) {
          if (res.response) {
            if (res.response.data.urls.length) {
              setDownloadLink(res.response.data.urls);
            }
          }
        }
      }, 3000);
      return () => clearInterval(intervalId);
    }
  }, [downloadLink]);

  return showLoader ? (
    <Loading />
  ) : (
    <div className="dark:text-white min-h-screen">
      <StaticLogo />
      <div className="container mt-14 mx-auto max-w-screen-lg xl:max-w-screen-xl xl:px-10 px-10 ">
        <div className="flex items-center text-neutral-800 dark:text-slate-50 sm:w-[50%] sm:m-auto">
          <div className="lg:m-5 lg:p-8 rounded-lg md:shadow-button md:shadow-dark-input-bottom-right md:dark:shadow-dark-input py-[9px] px-[16px] text-center">
            <h1 className="dark:text-light-gray text-black-gray text-base md:text-xl font-medium">
              {EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                eventDetails.data.id
              ) && state?.cod
                ? "Thank you for your purchase, please collect your order at print store"
                : "Thank you for shopping with Algomage"}
            </h1>
            {eventDetails.photographer.domain && user.accessLevel ? (
              <a
                href={`//${eventDetails.photographer.domain}/${
                  user.accessLevel ?? "facerec"
                }/${eventDetails.data.slug}${
                  user.accessLevel === "view"
                    ? ""
                    : `/results/${user.search_id}`
                }`}
              >
                {" "}
                <BackButton
                  className="w-56 flex mx-auto mt-6 justify-center"
                  text="Continue Shopping"
                />
              </a>
            ) : (
              <Link
                to={
                  EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                    eventDetails.data.id
                  )
                    ? `/facerec/${eventDetails.data.slug}/results/${user.search_id}`
                    : `/view/${eventDetails.data.name}`
                }
              >
                <BackButton
                  className="w-56 flex mx-auto mt-6 justify-center"
                  text="Continue Shopping"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
      {downloadId ? (
        <div className="mt-20 px-10">
          <div className="text-2xl font-bold">Download Links</div>
          <div className="mt-10 text-center">
            {downloadLink.length > 0 ? (
              downloadLink.map((link, i) => (
                <a onClick={() => downloadingZip(link)} href={link} key={i}>
                  <button
                    className={`rounded-[10px] shadow-button shadow-dark-input-bottom-right dark:shadow-dark-input py-[9px] px-[16px] dark:text-slate-100 font-semibold font-semibold text-base w-56 flex mx-auto mt-6 justify-center`}
                  >
                    Download Link {i + 1}
                  </button>
                </a>
              ))
            ) : (
              <>
                <span>
                  You will be notified via email once your download is ready.
                  <br />
                  You can also wait on this page
                </span>
                <h3 className="text-center mt-3 text-dark">
                  Request Processing!!
                </h3>
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {orderId && !!images.length ? (
        <div className="mt-20 px-10 mb-10">
          <div className="text-2xl font-bold">Images you bought</div>
          <div className="mt-10 items-center flex flex-wrap gap-x-8 gap-y-8">
            {images.map((el) => (
              <div key={el.event_image_url} className="relative group">
                <img
                  className="max-w-[300px] object-contain"
                  src={el.event_image_url}
                  alt={el.event_image_id}
                />
                <div className="hidden group-hover:flex absolute actions-button z-[2] h-12  justify-end pr-6 bottom-0">
                  <button
                    onClick={() =>
                      handleDownload(el.event_image_url, el.event_image_id)
                    }
                  >
                    <DownloadIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default CompleteShopping;
