import { useState, useEffect, useContext } from "react";

import { useEvent } from "../../../Store/event";
import { useUser } from "../../../Store/user";
import { InputEmail, InputPhone, InputText } from "../Input";
import { guestLogin, loginFaceReg } from "../../../apis/events";
import Button from "../Button";
import { ThemeContext } from "../../App";
import {
  EMIAL_REGEX,
  LOGIN_BE_MESSAGE_FOR_LIMIT,
  MAX_FACE_REG_REACHED_POPUP,
  MAX_FACE_SEARCH_LIMIT_REACH_BE_MESSAGE,
  MAX_GUEST_LIMIT_REACH_BE_MESSAGE,
  MOBILE_REGEX_TO_CHECK_DIGITS,
  PHOTOGRAPHER_WHERE_PHONE_IS_REQUIRED,
  SHOW_MOBILE_INSTEAD_OF_EMAIL,
} from "../../../constants";

const LABEL_CLASSNAME = "text-xs md:text-sm";

const NAME_ERROR = "Please Enter Valid Name";

const PHONE_ERROR = "Pleas enter valid phone";

const PASSWORD_ERROR = "Please Enter Password";

const DetailsForm = ({ setLoading, close }) => {
  const className = useContext(ThemeContext);
  const { user, setUser } = useUser();
  const eventDetails = useEvent().response;

  const [email, setEmail] = useState(user.email);
  const [fname, setFname] = useState(user.fname);
  const [lname, setLname] = useState(user.lname);
  const [phone, setPhone] = useState(
    !!eventDetails.data.showMobileField ? user.phone : null
  );
  const [password, setPassword] = useState(user.password);
  const [isDisabled, setDisable] = useState(true);
  const [inputError, setInputError] = useState({
    fnameError: "",
    emailError: "",
    lnameError: "",
    phoneError: "",
  });

  const emailFieldIsMobile = SHOW_MOBILE_INSTEAD_OF_EMAIL.includes(
    eventDetails.photographer.id
  );
  const EMAIL_ERROR =
    "Invalid" + emailFieldIsMobile ? " Mobile number" : " email address";

  const checkFname = () => {
    if (fname === "" || fname?.trim().length < 2) {
      setInputError((prev) => ({ ...prev, fnameError: NAME_ERROR }));
      return false;
    } else {
      setInputError((prev) => ({ ...prev, fnameError: "" }));
      return true;
    }
  };

  const checkLname = () => {
    if (lname === "" || lname?.trim().length < 2) {
      setInputError((prev) => ({ ...prev, lnameError: NAME_ERROR }));
      return false;
    } else {
      setInputError((prev) => ({ ...prev, lnameError: "" }));
      return true;
    }
  };

  const checkEmail = () => {
    if (
      emailFieldIsMobile
        ? email === "" || MOBILE_REGEX_TO_CHECK_DIGITS.exec(email) === null
        : email === "" || EMIAL_REGEX.exec(email) === null
    ) {
      setInputError((prev) => ({ ...prev, emailError: EMAIL_ERROR }));
      return false;
    } else {
      setInputError((prev) => ({ ...prev, emailError: "" }));
      return true;
    }
  };

  const checkPassword = () => {
    if (!!eventDetails.data.requiresPassword && password === "") {
      setInputError((prev) => ({ ...prev, passwordError: PASSWORD_ERROR }));
      return false;
    } else {
      setInputError((prev) => ({ ...prev, passwordError: "" }));
      return true;
    }
  };

  const checkPhone = () => {
    if (phone !== "") {
      setInputError((prev) => ({ ...prev, phoneError: PHONE_ERROR }));
      return false;
    } else {
      setInputError((prev) => ({ ...prev, phoneError: "" }));
      return true;
    }
  };

  const handleSubmit = async () => {
    if (
      checkEmail() &&
      checkLname() &&
      checkFname() &&
      checkPassword() &&
      (PHOTOGRAPHER_WHERE_PHONE_IS_REQUIRED.includes(
        eventDetails.photographer.id
      )
        ? checkPhone()
        : true)
    ) {
      const newUser = {
        ...user,
        email,
        fname: fname.trim(),
        lname: lname.trim(),
        phone: emailFieldIsMobile ? email : phone,
        password,
      };
      setDisable(true);
      try {
        if (!user.accessToken && eventDetails.data.isPublished) {
          const result = await guestLogin(
            eventDetails.data.id,
            email,
            password
          );
          if (result.error === false) {
            const loggedInUserData = {
              ...user,
              email: email,
              accessToken: result.response.accessToken,
              isAdmin: result.response.isSuperAdmin,
              isValid: true,
              pin: eventDetails.pin ? eventDetails.pin : "",
              search_id: result.response.searchId,
              fname: result.response.name?.split(" ")[0],
              lname: result.response.name?.split(" ")[0],
              phone: emailFieldIsMobile ? email : result.response.phone,
              id: result.response.guestId,
              password: password,
            };
            setUser(loggedInUserData);
            newUser.pin = eventDetails.pin;
            newUser.accessToken = result.response.accessToken;
          } else {
            if (result.message === LOGIN_BE_MESSAGE_FOR_LIMIT) {
              MAX_FACE_REG_REACHED_POPUP();
              close();
              return;
            }
            setUser({ ...user, error: result.message });
            if (
              result.e.password &&
              typeof result.e.password[0] !== "undefined"
            ) {
              setInputError({
                ...inputError,
                passwordError: result.e.password[0],
              });
              setDisable(false);
            } else {
              window.Toast.fire({
                icon: "error",
                title: "Something went wrong. Please try again after sometime.",
              });
              close();
            }
            return;
          }
        }
        setDisable(false);
        setLoading(true);
        const result = await loginFaceReg(eventDetails.data.id, newUser);
        if (result.error === false) {
          /**Deleting the password field so that the folders useEffect is not triggered */
          if (!eventDetails.data.isPublished) delete newUser.password;
          const loggedInUserData = {
            ...newUser,
            isValid: true,
            id: result.response.data.id,
          };
          setUser((prev) => ({ ...prev, ...loggedInUserData }));
        } else {
          if (
            [
              MAX_FACE_SEARCH_LIMIT_REACH_BE_MESSAGE,
              MAX_GUEST_LIMIT_REACH_BE_MESSAGE,
            ].includes(result.message)
          ) {
            MAX_FACE_REG_REACHED_POPUP(!!eventDetails.data.isPublished);
            close();
          } else {
            setUser({ ...newUser, error: result.message });
            window.Toast.fire({
              icon: "error",
              title: "Something went wrong. Please try again after sometime.",
            });
            close();
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setDisable(true);
    if (
      email &&
      fname &&
      lname &&
      (PHOTOGRAPHER_WHERE_PHONE_IS_REQUIRED.includes(
        eventDetails.photographer.id
      ) && !emailFieldIsMobile
        ? phone
        : true)
    ) {
      if (!!eventDetails.data.requiresPassword) password && setDisable(false);
      else setDisable(false);
    }
  }, [email, fname, lname, password, phone]);

  const handleKeyDown = (e) => {
    if (
      (e.key === " " && fname.length === 0) ||
      (e.key === " " && lname.length === 0) ||
      (e.key === " " && phone.length === 0)
    ) {
      e.preventDefault();
    }
  };

  const onBlur = (e) => {
    if (e.target.id === "fname") checkFname();
    else if (e.target.id === "lname") checkLname();
    else if (e.target.id === "email") checkEmail();
    else if (e.target.id === "password") checkPassword();
  };

  return (
    <>
      <div className="mb-4 italic text-black-gray dark:text-light-gray text-xs">
        Our cutting edge algorithms detect every face in every photo captured
        and measure 48 different face vector points with a 99.2% accuracy! (Even
        if you are wearing sunglasses or are turned away from the camera!) No
        more searching through albums to find your photos…we’ll find them in
        seconds for you!
        <div>PS: Your data is completely secure with us. No spams! </div>
      </div>
      <div className="pb-4">
        <InputText
          tabIndex={1}
          className="mb-4 lg:mb-0"
          label="First Name"
          required
          value={fname}
          onChange={setFname}
          error={inputError.fnameError}
          onKeyDown={handleKeyDown}
          lableClassName={LABEL_CLASSNAME}
          autoFocus
          onBlur={onBlur}
          id="fname"
        />
      </div>
      <div className="pb-4">
        <InputText
          tabIndex={2}
          label="Last Name"
          required
          lableClassName={LABEL_CLASSNAME}
          value={lname}
          onChange={setLname}
          error={inputError.lnameError}
          onKeyDown={handleKeyDown}
          id="lname"
          onBlur={onBlur}
        />
      </div>
      {emailFieldIsMobile ? (
        <InputPhone
          autoFocus
          label="Phone"
          required
          lableClassName={LABEL_CLASSNAME}
          value={email}
          onChange={setEmail}
          error={inputError.emailError}
          id="email"
          onBlur={onBlur}
        />
      ) : (
        <div className="pb-4">
          <InputEmail
            tabIndex={3}
            label="Email"
            required
            lableClassName={LABEL_CLASSNAME}
            value={email}
            onChange={setEmail}
            error={inputError.emailError}
            id="email"
            onBlur={onBlur}
          />
        </div>
      )}
      {(!!eventDetails.data.showMobileField ||
        PHOTOGRAPHER_WHERE_PHONE_IS_REQUIRED.includes(
          eventDetails.photographer.id
        )) &&
        !emailFieldIsMobile && (
          <div className="pb-4">
            <InputPhone
              tabIndex={4}
              label="Phone"
              className={className}
              value={phone}
              onChange={setPhone}
              onKeyDown={handleKeyDown}
              lableClassName={LABEL_CLASSNAME}
              error={inputError.phoneError}
              required={PHOTOGRAPHER_WHERE_PHONE_IS_REQUIRED.includes(
                eventDetails.photographer.id
              )}
            />
          </div>
        )}
      {!!eventDetails.data.requiresPassword && (
        <div className="pb-4">
          <InputText
            tabIndex={5}
            label="Password"
            type="password"
            id="password"
            className={className}
            value={password}
            onChange={setPassword}
            onKeyDown={handleKeyDown}
            lableClassName={LABEL_CLASSNAME}
            required
            error={inputError.passwordError}
            onBlur={onBlur}
            readOnly={user.password ? true : false}
          />
        </div>
      )}
      <div className="flex justify-center mt-2">
        <Button
          disabled={isDisabled}
          onClick={handleSubmit}
          text="Find My Image"
        />
      </div>
    </>
  );
};

export default DetailsForm;
